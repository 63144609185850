import React from "react";
import "../css/main.css";
import "../css/contact.css";

import ContactForm from "../components/ContactForm";

const Kontakt = () => {
  return (
    <>
      <main className="lr-margin has-margin-top-large">
        <section>
          <h1 className="tb-margin">Kontakt</h1>
          <ContactForm></ContactForm>
        </section>

          <figure className="img-karte">
            <a
              className="link"
              href="https://maps.app.goo.gl/FzezdSbSvJjqkYDx9"
            >
              <img
                src="../ressources/Wiesdorfer-Platz-11.webp"
                alt="Karte-Wiesdorfer-Platz-11 Leverkusen"
              />
            </a>
            <figcaption>
              <a href="https://maps.app.goo.gl/FzezdSbSvJjqkYDx9">
                <p>De-Fender IT GmbH - Google Maps</p>
              </a>
            </figcaption>
          </figure>
      </main>
    </>
  );
};

export default Kontakt;
