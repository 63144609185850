import React from "react";

import "../css/main.css";
import "../css/error.css";

const NotFound = () => {
  return (
    <main className="t-margin lr-margin error-main">
      <img
        className="t-margin error-img"
        src="../ressources/404.svg"
        alt="Eine Scheint eine Taschenlampe an die Wand, im Lichtkegel steht ERROR, NOT FOUND 404"
      />
      <div>
        <h1>Ups! Diese Seite ist auf Wanderschaft.</h1>
        <p>
          Diese Seite ist leider nicht verfügbar. Hier können sie zur
          <a    className="link" 
                style={{ color: "#48a61c", margin: "0px 4px" }}
                href="/">
            Startseite
          </a>
          zurückzukehren.
        </p>
      </div>
    </main>
  );
};

export default NotFound;
