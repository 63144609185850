import React, { useEffect, useState } from 'react';
import ServiceCardMobile from './ServiceCardMobile';

const CardGridMobile = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetch('/json/services.json')
      .then(response => response.json())
      .then(data => setCards(data))
      .catch(error => console.error('Error loading cards:', error));
  }, []);

     return (
      <div id="card-grid-mobile" className="cardGrid">
        {cards.map((card, index) => (
          <ServiceCardMobile
            key={index}
            title={card.title}
            description={card.description}
            leistungen={card.leistungen}
          />
        ))}
      </div>
    );
  };

export default CardGridMobile;    