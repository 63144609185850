import React, { useEffect, useState } from 'react';
import '../css/headline-animation-new.css';

const TypewriterComponent = () => {
  const words1 = [
    "Systeme",
    "Schwachstellen",
    "Mitarbeiter",
    "Anwendungen",
    "Angreifer",
  ];
  const words2 = [
    "Übersicht",
    "Patches",
    "Schulungen",
    "Absicherung",
    "Verteidigung",
  ];

  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typewriter1NeedsCursor, setTypewriter1NeedsCursor] = useState(false);
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const writeSpeed = 250; // Geschwindigkeit des Schreibens in Millisekunden
  const deleteSpeed = 75; // Geschwindigkeit des Löschens in Millisekunden
  const pauseBeforeDelete = 750; // Pause bevor das Löschen beginnt in Millisekunden

  useEffect(() => {
    const typeWriter = () => {
      let word1 = words1[index];
      let word2 = words2[index];

      if (!isDeleting) {
        if (text1.length < word1.length) {
          setText1(text1 + word1.charAt(text1.length));
          setTypewriter1NeedsCursor(true);
        } else if (text2.length < word2.length) {
          setText2(text2 + word2.charAt(text2.length));
          setTypewriter1NeedsCursor(false);
        } else {
          setTimeout(() => {
            setIsDeleting(true);
          }, pauseBeforeDelete);
        }
      } else {
        if (text2.length > 0) {
          setText2(text2.substring(0, text2.length - 1));
          setTypewriter1NeedsCursor(false);
        } else if (text1.length > 0) {
          setText1(text1.substring(0, text1.length - 1));
          setTypewriter1NeedsCursor(true);
        } else {
          setIsDeleting(false);
          setIndex((index + 1) % words1.length);
        }
      }
    };

    const speed = isDeleting ? deleteSpeed : writeSpeed;
    const timeoutId = setTimeout(typeWriter, speed);

    return () => clearTimeout(timeoutId);
  }, [text1, text2, isDeleting, index]);

  return (
    <div>
      <div className="headings-wrapper">
        <h2 className="animated-Headline">Sie haben die</h2>
        <h2 id="typewriter1" className={`animated-Headline typewriter ${typewriter1NeedsCursor ? 'active' : ''}`}>{text1}</h2>
      </div>
      <div className="headings-wrapper">
        <h2 className="animated-Headline">Wir haben die</h2>
        <h2 id="typewriter2" className={`animated-Headline typewriter ${!typewriter1NeedsCursor ? 'active' : ''}`}>{text2}</h2>
      </div>
    </div>
  );
};

export default TypewriterComponent;