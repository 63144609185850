import React from 'react';
import { Link } from 'react-router-dom';

import '../css/main.css';
import '../css/footer.css';

const Footer = () => {
  return (
    <footer className="footer no-margin t-margin">
      <div className="outer-row">
        <div className="inner-row">
          <ul className="footer-List">
          <li ><Link className="footer-button" to="/impressum">Impressum</Link></li>

          <li ><Link className="footer-button" to="/datenschutz">Datenschutz</Link></li>

          </ul>
          <ul className="footer-List">
          <li ><Link className="footer-button" to="/kontakt">Kontakt</Link></li>

          <li ><Link className="footer-button" to="/ressourcen">Ressourcen</Link></li>

          </ul>
          <ul className="footer-List">
            {/* <li><a href="https://de.linkedin.com/in/jonas-fender-875540233" className="footer-button">
                          Stellenangebote
                      </a></li> */}
          </ul>
        </div>
        <div className="info-container column">
          <img
            src="../ressources/logo_text.svg"
            alt="DeFender IT Logo"
            width="168"
          />
          <p style={{ fontSize: '16px', lineHeight: 1.5 }}>
            De-Fender IT GmbH <br />
            Wiesdorfer Platz 11 <br />
            51373 Leverkusen <br />
            <a className="link" href="tel:+4921460273713">+49 (0) 214 60273713</a>
            <br />
            <a className="link" href="mailto:info@de-fender.it">
              info@de-fender.it
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;