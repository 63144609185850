import React, { useEffect, useState } from "react";

import "../css/stats.css";

const StatCards = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    fetch("/json/stats.json")
      .then(console.log(stats))
      .then((response) => response.json())
      .then((data) => setStats(data.stats))
      .catch((error) => console.error("Error loading stats:", error));
  }, []);

  return (
    <div className="stat-grid">
      {stats.map((stat, index) => (
        <div className="stat-card" key={index}>
          <p>
            <span className="stat-Header">{stat.title}</span>
            <span className="stat-highlight">{stat.highlight}</span>
          </p>
          <div
            className="body"
            dangerouslySetInnerHTML={{ __html: stat.body }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default StatCards;
