import React from "react";

const DevBanner = () => {

  const isDevMode = process.env.REACT_APP_DEV_MODE === 'true';

  if (!isDevMode) {
    return null;
  } else {
    return (
      <>
        <span style={{ display: "block", backgroundColor: '#f93724', width: '100%', height: 'fit-content', padding: 10, opacity: 50, position: "fixed", top: 50, zIndex: 99999999 }}>
          <h2>!development Build!</h2>
          <span>Dies ist die Entwicklungsumgebung, bitte besuchen sie <a href="https://de-fender.it/"> <h2>de-fender.it</h2> </a></span>
          <span> Dev Mode ENV =<pre>{process.env.REACT_APP_DEV_MODE}</pre></span>
        </span>
      </>
    );
  }
}

export default DevBanner;