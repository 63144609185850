import React from "react";

function StatGrid({ children}) {
  return (
    <div className="stat-grid">
      {children}
    </div>
  );
}

export default StatGrid;