import React from "react";
import "../css/button-contact.css";

function contactClick() {
  window.location.href = "/kontakt";
}

function ContactButtonLarge({ children }) {
  return (
    <div className="contact-button-large-container">
      <button className="contact-button-large" onClick={contactClick}>
        <p>Nehmen sie Kontakt auf</p>
      </button>
    </div>
  );
}

export default ContactButtonLarge;
