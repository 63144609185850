import React from "react";

function UnderlineHeadline({ children, color, paddingBottom }) {
  return (
    <h2
      className="heading-underlined"
      style={{
        textDecorationColor: color,
        paddingBottom: paddingBottom,
      }}
    >
      {children}
    </h2>
  );
}

export default UnderlineHeadline;