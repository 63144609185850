import React from "react";
import { Link } from 'react-router-dom';
import UnderlineHeadline from "./UnderlineHeadline";

import "../css/ueber-uns.css";

function UeberUns() {
    return(
    <section className="ÜberUns left-margin">
        <div className="ueberUns-row">
          <div className="ueberUns-block">
                <UnderlineHeadline color="#ffffff" paddingBottom={20}>Über uns</UnderlineHeadline>
            <p>
              Unser Team besteht aus Experten, die sich leidenschaftlich für
              Ihre IT-Sicherheit einsetzen. Bei uns finden Sie individuelle
              Ansprechpartner, die mit Ihnen gemeinsam maßgeschneiderte Lösungen
              entwickeln. Erfahren Sie mehr über unser engagiertes Team und
              nehmen sie
              <strong>
                <Link className="link is-green" to="/kontakt"> Kontakt </Link>
              </strong>
              auf.
            </p>
          </div>

          <img
            className="TeamHighFive"
            src="./ressources/ueber-uns-grafik.svg"
            alt="Drei Personen geben sich ein High-Five, in der Mitte schwebt das De-Fender IT Logo zwischen ihren händen."
            loading="lazy"
            />
        </div>
      </section>
    )
}

export default UeberUns;