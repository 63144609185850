import React from 'react';

const InfoBox = React.forwardRef(({ isVisible }, ref) => (
  <div id="infoBox" style={{ display: isVisible ? 'block' : 'none' }}>
    <div id="iconContainer"></div>

        {/*<p id="infoBoxDeafaultText">
      Klicken Sie auf eine Karte, um weitere Details zu erfahren.
    </p> 
    */}

    <div id="textContainer" ref={ref}></div>
  </div>
));

export default InfoBox;