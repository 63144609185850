import React from 'react';
import Typewriter from './TypewriterHeadline';

import '../css/headline-animation-new.css';
import '../css/landing.css';
import ContactButtonRow from './ContactButton-row.js';

const icon_logo_glow = '/ressources/icon_logo_glow.svg';
const icon_logo_no_glow = '/ressources/icon_no_glow_no_white_fade_opti.svg';

function CatchphraseBlock(){
    return(
        <section className="catchphrase-block lr-margin">
        <div className="row">
          <img
            className="Imageblock-mobile"
            src={icon_logo_no_glow}
            width={288}
            height={297}
            alt="De-Fender IT Logo_Glow"
          />
          <div className="textblock tb-margin">
            <Typewriter/>
            <p className="landingText">
              Sicherheit durch Überblick. Gemeinsam stärken wir Ihre
              IT-Infrastruktur und sorgen dafür, dass Sie jederzeit die volle
              Kontrolle über Ihre Systeme behalten. Wir bieten Ihnen umfassende
              Sicherheitsstrategien, um potenzielle Risiken zu identifizieren
              und zu beheben. So können Sie sich auf das Wesentliche
              konzentrieren, während wir Ihre IT-Infrastruktur mit höchster
              Präzision und Sorgfalt absichern.
            </p>
            
            <ContactButtonRow/>
          </div>
          <img
            className="Imageblock"
            src={icon_logo_glow}
            alt="De-Fender IT Logo_Glow"
            width={810}
            height={810}
          />
        </div>
      </section>
    )
}

export default CatchphraseBlock;