import React, { useState } from 'react';

import '../css/main.css'
import '../css/services.css'

const ServiceCard = ({ title, description, leistungen, onClick }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className="card-wrapper"
      data-js-card
      onMouseOver={() => setIsActive(true)}
      onMouseOut={() => setIsActive(false)}
      onClick={onClick}
    >
      <div className={`card ${isActive ? 'is-active' : ''}`}>
        <div className="header">
          <h3>{title}</h3>
        </div>
        <div className="body">
        <p>{description}</p>
        <h3>Unsere Leistungen</h3>
        <p> {leistungen}</p>
        </div>
        <div className="fade-out-2"></div>
        <div className="after-slideout">
          <img src="./ressources/arrow_forward_ios.svg" alt="arrow_forward_ios" />
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;