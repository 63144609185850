import React from "react";

const Ressourcen = () => {
    return (
        <main className="lr-margin has-margin-top-large">
    <section>
      <h1 className="tb-margin">verwendete Ressourcen</h1>

      <h2>Designelemente</h2>
      <p>
        <a
          className="link"
          href="https://fonts.google.com/specimen/Be+Vietnam+Pro"
        >
          {" "}
          Google Fonts 'Be Vietnam Pro'
        </a>{" "}
        <a href="https://openfontlicense.org/documents/OFL.txt">
          {" "}
          (SIL Open Font License, Version 1.1){" "}
        </a>
      </p>
      <p>
        <a className="link" href="https://fonts.google.com/icons">
          {" "}
          Google Material Symbols
        </a>{" "}
        <a href="https://www.apache.org/licenses/LICENSE-2.0.html">
          {" "}
          (Apache License, Version 2.0)
        </a>
      </p>
      <p>
        <a
          className="link"
          href="https://storyset.com/illustration/error-429/pana"
        >
          Server Illustration von Storyset
        </a>
      </p>
      <p>
        <a
          className="link"
          href="https://storyset.com/illustration/teamwork-high-five/amico"
        >
          Team Illustration von Storyset
        </a>
      </p>
      <p>
        <a
          className="link"
          href="https://storyset.com/illustration/404-error-with-person-looking-for/amico"
        >
          404 Illustration von Storyset
        </a>
      </p>
    </section>
    <div style={{ height: 400 }} />
  </main>
    );
}

export default Ressourcen;